@-webkit-keyframes appear {
  0% {
    background-color: rgb(182, 255, 182);
  }
  100% {
    background-color: rgb(158, 231, 207);
  }
}
@keyframes appear {
  0% {
    background-color: rgb(182, 255, 182);
  }
  100% {
    background-color: rgb(158, 231, 207);
  }
}
@-webkit-keyframes drop {
  0% {
    transform: scaleY(0%);
  }
  100% {
    transform: scaleY(100%);
    opacity: 1;
  }
}
@keyframes drop {
  0% {
    transform: scaleY(0%);
  }
  100% {
    transform: scaleY(100%);
    opacity: 1;
  }
}
@-webkit-keyframes dropArrow {
  0% {
    transform: scaleY(0%) rotate(45deg);
  }
  100% {
    transform: scaleY(100%) rotate(45deg);
    opacity: 1;
  }
}
@keyframes dropArrow {
  0% {
    transform: scaleY(0%) rotate(45deg);
  }
  100% {
    transform: scaleY(100%) rotate(45deg);
    opacity: 1;
  }
}
@-webkit-keyframes reveal1 {
  0% {
    transform: scale(0%, 0%);
  }
  80% {
    transform: scale(130%, 130%);
  }
  100% {
    transform: scale(100%, 100%);
  }
}
@keyframes reveal1 {
  0% {
    transform: scale(0%, 0%);
  }
  80% {
    transform: scale(130%, 130%);
  }
  100% {
    transform: scale(100%, 100%);
  }
}
@-webkit-keyframes rotate-up {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes rotate-up {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@-webkit-keyframes rotate-down {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
@keyframes rotate-down {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
* {
  margin: 0;
  font-family: "Overpass", sans-serif;
  color: rgb(200, 255, 200);
  box-sizing: border-box;
}

body {
  background-color: darkseagreen;
  overflow-y: hidden;
  overflow-x: auto;
}
body::-webkit-scrollbar {
  height: 7px;
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(100, 129, 100);
  border-radius: 3px;
}
body::-webkit-scrollbar-track {
  margin: 10px 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  min-width: 775px;
  min-height: 775px;
}

@media (max-width: 600px) {
  .homepage {
    grid-template-rows: 100px 550px;
  }
  .homepage-title {
    font-size: 70px;
    margin-bottom: 10px;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 601px) {
  .homepage {
    grid-template-columns: 1000px;
    grid-template-rows: 150px 300px;
  }
  .homepage-title {
    font-size: 100px;
    margin-bottom: 30px;
  }
  .form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.homepage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-content: center;
  background-color: darkseagreen;
}
.homepage .homepage-title {
  font-weight: 700;
  -webkit-text-stroke: 1px rgb(100, 129, 100);
  text-shadow: 3px 3px 5px rgb(100, 129, 100);
  cursor: default;
  text-align: center;
}
.homepage .form-container .homepage-login,
.homepage .form-container .homepage-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 7vw;
}
.homepage .form-container .homepage-login h1,
.homepage .form-container .homepage-signup h1 {
  font-weight: 900;
  -webkit-text-stroke: 1px rgb(100, 129, 100);
}
.homepage .form-container .homepage-login form,
.homepage .form-container .homepage-signup form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.homepage .form-container .homepage-login form .login-incorrect,
.homepage .form-container .homepage-login form .signup-incorrect,
.homepage .form-container .homepage-signup form .login-incorrect,
.homepage .form-container .homepage-signup form .signup-incorrect {
  color: red;
  margin-bottom: 5px;
  pointer-events: none;
  opacity: 0;
}
.homepage .form-container .homepage-login form .login-incorrect.reveal1,
.homepage .form-container .homepage-login form .signup-incorrect.reveal1,
.homepage .form-container .homepage-signup form .login-incorrect.reveal1,
.homepage .form-container .homepage-signup form .signup-incorrect.reveal1 {
  color: red;
  opacity: 1;
  -webkit-animation: reveal1 0.1s ease-in forwards;
          animation: reveal1 0.1s ease-in forwards;
}
.homepage .form-container .homepage-login form input,
.homepage .form-container .homepage-signup form input {
  width: 200px;
  height: 40px;
  position: relative;
  margin-bottom: 15px;
  padding: 10px;
  background-color: rgb(200, 255, 200);
  border: solid 1px rgb(100, 129, 100);
  border-radius: 10px;
  color: rgb(100, 129, 100);
  font-weight: 700;
  outline: none;
}
.homepage .form-container .homepage-login form input::-moz-placeholder, .homepage .form-container .homepage-signup form input::-moz-placeholder {
  opacity: 0.7;
  font-weight: 500;
}
.homepage .form-container .homepage-login form input:-ms-input-placeholder, .homepage .form-container .homepage-signup form input:-ms-input-placeholder {
  opacity: 0.7;
  font-weight: 500;
}
.homepage .form-container .homepage-login form input::placeholder,
.homepage .form-container .homepage-signup form input::placeholder {
  opacity: 0.7;
  font-weight: 500;
}
.homepage .form-container .homepage-login form .login-button,
.homepage .form-container .homepage-login form .signup-button,
.homepage .form-container .homepage-signup form .login-button,
.homepage .form-container .homepage-signup form .signup-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

@media (max-width: 1100px) {
  nav {
    padding: 0 50px;
  }
}
@media (min-width: 1101px) {
  nav {
    padding: 0 5vw;
  }
}
nav {
  width: 100%;
  height: 100px;
  min-width: 1100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -moz-column-gap: 10px;
       column-gap: 10px;
  justify-items: center;
  align-items: center;
}
nav .new-reminder,
nav .title,
nav .account-container {
  transform: translateY(20px);
}
nav .account-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  justify-self: flex-end;
  min-width: 250px;
  gap: 15px;
  z-index: 5;
}
nav .account-container h3 {
  -webkit-text-stroke: 1px rgb(100, 129, 100);
  text-shadow: 3px 3px 5px rgb(100, 129, 100);
  text-align: center;
  white-space: nowrap;
}
nav .account-container .dropdown-button {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  position: relative;
  border: solid 1px rgb(100, 129, 100);
  border-radius: 15px;
  background-color: rgb(200, 255, 200);
  transition: 0.3s ease;
  cursor: pointer;
}
nav .account-container .dropdown-button .dropdown-arrow {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
}
nav .account-container .dropdown-button .dropdown-arrow.rotate-up {
  -webkit-animation: rotate-up 0.2s ease forwards;
          animation: rotate-up 0.2s ease forwards;
}
nav .account-container .dropdown-button .dropdown-arrow.rotate-down {
  -webkit-animation: rotate-down 0.2s ease forwards;
          animation: rotate-down 0.2s ease forwards;
}
nav .account-container .dropdown-button:hover {
  transform: translate(-1px, -1px);
  box-shadow: 1px 1px 3px rgb(100, 129, 100);
  transition: 0.2s ease;
}
nav .account-container .dropdown-button:active {
  box-shadow: inset 2px 2px 3px rgb(100, 129, 100);
}
nav .account-container .dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  gap: 5px;
}
nav .account-container .dropdown.reveal2 {
  width: 150px;
  height: 80px;
  opacity: 1;
  background-color: rgb(200, 255, 200);
  border: solid 1px rgb(100, 129, 100);
  border-radius: 10px;
  padding: 5px 0;
}
nav .account-container .dropdown .change-pw,
nav .account-container .dropdown .delete-acc {
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  color: rgb(100, 129, 100);
  font-weight: 700;
  cursor: pointer;
}
nav .account-container .dropdown .change-pw:hover,
nav .account-container .dropdown .delete-acc:hover {
  background-color: rgba(100, 129, 100, 0.5);
}
nav .account-container .sign-out {
  min-width: 103px;
}
nav .account-container .sign-out:hover {
  box-shadow: 3px 3px 5px rgb(100, 129, 100);
  transform: translate(-1px, -1px);
  transition: 0.2s ease;
}
nav .new-reminder {
  justify-self: flex-start;
  min-width: 176px;
}
nav .new-reminder:hover {
  box-shadow: 3px 3px 5px rgb(100, 129, 100);
  transform: translate(-1px, 19px);
  transition: 0.2s ease;
}
nav .new-reminder,
nav .sign-out {
  color: rgb(100, 129, 100);
  font-size: 20px;
  font-weight: 700;
  padding: 5px 10px;
  padding-top: 7px;
  background-color: rgb(200, 255, 200);
  border: solid 1px rgb(100, 129, 100);
  border-radius: 10px;
  transition: 0.3s ease;
  cursor: pointer;
}
nav .new-reminder:active,
nav .sign-out:active {
  box-shadow: inset 3px 3px 5px rgb(100, 129, 100);
}
nav .title {
  font-size: 50px;
  -webkit-text-stroke: 1px rgb(100, 129, 100);
  text-shadow: 3px 3px 5px rgb(100, 129, 100);
  cursor: default;
}
nav .account-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1100px) {
  .reminder-wall {
    padding: 0 50px;
  }
}
@media (min-width: 1101px) {
  .reminder-wall {
    padding: 0 5vw;
  }
}
.reminder-wall {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 1100px;
}
.reminder-wall .empty {
  transform: translateY(-10vh);
  cursor: default;
}
.reminder-wall .tile-container {
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-template-rows: repeat(auto-fit, 300px);
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 20px;
  overflow-y: auto;
  align-items: flex-start;
  justify-content: flex-start;
  border: solid 1px rgb(100, 129, 100);
  border-radius: 10px;
  background-color: rgb(200, 255, 200);
  padding: 20px;
}
.reminder-wall .tile-container::-webkit-scrollbar {
  width: 7px;
}
.reminder-wall .tile-container::-webkit-scrollbar-thumb {
  background-color: rgb(100, 129, 100);
  border-radius: 3px;
}
.reminder-wall .tile-container::-webkit-scrollbar-track {
  margin: 10px 0;
}

.tile {
  width: 300px;
  height: 300px;
  align-self: center;
  justify-self: center;
  background-color: rgb(158, 231, 207);
  border: solid 1px rgb(120, 175, 157);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  -webkit-animation-name: appear;
          animation-name: appear;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.tile:hover {
  box-shadow: 3px 3px 5px rgb(100, 129, 100);
  transform: translate(-1px, -1px);
  transition: all 0.2s ease;
}
.tile .delete {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 15px;
  cursor: pointer;
  right: -15px;
  top: -15px;
  color: rgb(100, 129, 100);
  background-color: rgb(158, 231, 207);
  border: solid 1px rgb(120, 175, 157);
}
.tile .delete img {
  width: 20px;
  height: 20px;
}
.tile .delete:hover {
  box-shadow: 3px 3px 5px rgb(100, 129, 100);
  transform: translate(-1px, -1px);
  transition: all 0.3s ease;
}
.tile .tile-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  height: 100%;
  position: relative;
}
.tile .tile-content .tile-reminder {
  font-size: 15px;
  height: 1rem;
  color: rgb(100, 129, 100);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tile .tile-content .tile-datetime {
  font-size: 12px;
  color: rgb(100, 129, 100);
  opacity: 0.5;
}
.tile .tile-content .tile-description {
  height: 70%;
  font-size: 15px;
  color: rgb(100, 129, 100);
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.tile .tile-content .tile-remaining {
  color: rgb(100, 129, 100);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
}

.modal-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(30, 30, 30, 0.5);
}

.modal .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-15vh);
  z-index: 11;
}
.modal .modal-content .modal-exit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
  font-size: 40px;
  padding-top: 6px;
  padding-left: 6px;
  background-color: rgb(200, 255, 200);
  color: rgb(100, 129, 100);
  border: solid 2px rgb(100, 129, 100);
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.modal .modal-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 10px;
}
.modal .modal-content form .modal-reminder,
.modal .modal-content form .modal-description,
.modal .modal-content form .modal-datetime,
.modal .modal-content form .modal-submit {
  margin-bottom: 10px;
  background-color: rgb(200, 255, 200);
  color: rgb(100, 129, 100);
  border-radius: 10px;
  border: solid 2px rgb(100, 129, 100);
  font-size: 20px;
  outline: none;
  padding: 10px;
}
.modal .modal-content form .modal-reminder {
  width: 100%;
  height: 50px;
  overflow-x: auto;
}
.modal .modal-content form .modal-reminder::-moz-placeholder {
  opacity: 0.7;
}
.modal .modal-content form .modal-reminder:-ms-input-placeholder {
  opacity: 0.7;
}
.modal .modal-content form .modal-reminder::placeholder {
  opacity: 0.7;
}
.modal .modal-content form .modal-description {
  width: 100%;
  height: 200px;
  resize: none;
}
.modal .modal-content form .modal-description::-moz-placeholder {
  opacity: 0.7;
}
.modal .modal-content form .modal-description:-ms-input-placeholder {
  opacity: 0.7;
}
.modal .modal-content form .modal-description::placeholder {
  opacity: 0.7;
}
.modal .modal-content form .modal-description::-webkit-scrollbar {
  width: 7px;
}
.modal .modal-content form .modal-description::-webkit-scrollbar-thumb {
  background-color: rgb(100, 129, 100);
  border-radius: 3px;
}
.modal .modal-content form .modal-description::-webkit-scrollbar-track {
  margin: 10px 0;
}
.modal .modal-content form .modal-datetime {
  width: 55%;
  height: 50px;
  cursor: text;
}
.modal .modal-content form .modal-submit {
  width: 20%;
  height: 50px;
  cursor: pointer;
}

.detail-modal .dm-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
}
.detail-modal .dm-exit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  transform: rotate(45deg);
  font-size: 50px;
  padding-top: 9px;
  padding-left: 6px;
  color: rgb(100, 129, 100);
  background-color: rgb(206, 252, 236);
  cursor: pointer;
  z-index: 11;
}
.detail-modal .dm-edit {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
.detail-modal .dm-details,
.detail-modal .dm-edit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 500px;
  height: 500px;
  position: relative;
  margin-top: 20px;
  background-color: rgb(158, 231, 207);
  padding: 10px;
  border-radius: 10px;
  border: solid 1px rgb(120, 175, 157);
}
.detail-modal .dm-details .dm-cancel,
.detail-modal .dm-edit .dm-cancel {
  position: absolute;
  top: 10px;
  left: 10px;
}
.detail-modal .dm-details .dm-save,
.detail-modal .dm-edit .dm-save {
  align-self: flex-end;
}
.detail-modal .dm-details .dm-edit,
.detail-modal .dm-details .dm-delete,
.detail-modal .dm-details .dm-save,
.detail-modal .dm-details .dm-cancel,
.detail-modal .dm-edit .dm-edit,
.detail-modal .dm-edit .dm-delete,
.detail-modal .dm-edit .dm-save,
.detail-modal .dm-edit .dm-cancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background-color: rgb(206, 252, 236);
  border: solid 1px rgb(100, 129, 100);
  border-radius: 10px;
}
.detail-modal .dm-details .dm-edit > img,
.detail-modal .dm-details .dm-delete > img,
.detail-modal .dm-details .dm-save > img,
.detail-modal .dm-details .dm-cancel > img,
.detail-modal .dm-edit .dm-edit > img,
.detail-modal .dm-edit .dm-delete > img,
.detail-modal .dm-edit .dm-save > img,
.detail-modal .dm-edit .dm-cancel > img {
  width: 30px;
  height: 30px;
}
.detail-modal .dm-details form,
.detail-modal .dm-edit form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: rgb(100, 129, 100);
  gap: 10px;
}
.detail-modal .dm-details form .dm-form-reminder,
.detail-modal .dm-edit form .dm-form-reminder {
  height: 50px;
}
.detail-modal .dm-details form .dm-form-description,
.detail-modal .dm-edit form .dm-form-description {
  resize: none;
  height: 10em;
}
.detail-modal .dm-details form .dm-form-description::-webkit-scrollbar,
.detail-modal .dm-edit form .dm-form-description::-webkit-scrollbar {
  width: 7px;
}
.detail-modal .dm-details form .dm-form-description::-webkit-scrollbar-thumb,
.detail-modal .dm-edit form .dm-form-description::-webkit-scrollbar-thumb {
  background-color: rgb(100, 129, 100);
  border-radius: 5px;
}
.detail-modal .dm-details form .dm-form-description::-webkit-scrollbar-track,
.detail-modal .dm-edit form .dm-form-description::-webkit-scrollbar-track {
  margin: 10px 0;
}
.detail-modal .dm-details form .dm-form-datetime,
.detail-modal .dm-edit form .dm-form-datetime {
  flex-direction: row;
  cursor: text;
}
.detail-modal .dm-details form .dm-form-reminder,
.detail-modal .dm-details form .dm-form-description,
.detail-modal .dm-details form .dm-form-datetime,
.detail-modal .dm-edit form .dm-form-reminder,
.detail-modal .dm-edit form .dm-form-description,
.detail-modal .dm-edit form .dm-form-datetime {
  background-color: rgb(206, 252, 236);
  border: solid 1px rgb(100, 129, 100);
  border-radius: 10px;
  color: rgb(100, 129, 100);
  font-size: 30px;
  width: 100%;
}
.detail-modal .dm-details .dm-reminder,
.detail-modal .dm-details .dm-datetime,
.detail-modal .dm-details .dm-description,
.detail-modal .dm-details .dm-button-container,
.detail-modal .dm-edit .dm-reminder,
.detail-modal .dm-edit .dm-datetime,
.detail-modal .dm-edit .dm-description,
.detail-modal .dm-edit .dm-button-container {
  font-size: 30px;
  width: 100%;
}
.detail-modal .dm-details .dm-reminder,
.detail-modal .dm-edit .dm-reminder {
  color: rgb(100, 129, 100);
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin: 0;
}
.detail-modal .dm-details .dm-reminder::-webkit-scrollbar,
.detail-modal .dm-edit .dm-reminder::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgb(206, 252, 236);
  cursor: pointer;
}
.detail-modal .dm-details .dm-reminder::-webkit-scrollbar-thumb,
.detail-modal .dm-edit .dm-reminder::-webkit-scrollbar-thumb {
  background-color: rgb(100, 129, 100);
  border-radius: 3px;
}
.detail-modal .dm-details .dm-datetime,
.detail-modal .dm-edit .dm-datetime {
  color: rgb(100, 129, 100);
  opacity: 0.5;
}
.detail-modal .dm-details .dm-description,
.detail-modal .dm-edit .dm-description {
  color: rgb(100, 129, 100);
  margin-top: 10px;
  height: 10em;
  overflow-wrap: break-word;
  overflow: auto;
  overflow-x: hidden;
  white-space: pre-wrap;
}
.detail-modal .dm-details .dm-description::-webkit-scrollbar,
.detail-modal .dm-edit .dm-description::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgb(206, 252, 236);
  cursor: pointer;
}
.detail-modal .dm-details .dm-description::-webkit-scrollbar-thumb,
.detail-modal .dm-edit .dm-description::-webkit-scrollbar-thumb {
  background-color: rgb(100, 129, 100);
  border-radius: 3px;
}
.detail-modal .dm-details .dm-button-container,
.detail-modal .dm-edit .dm-button-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  bottom: 0;
  position: absolute;
  align-self: center;
  padding: 10px;
}
.detail-modal .dm-details .dm-button-container h5,
.detail-modal .dm-edit .dm-button-container h5 {
  color: rgb(100, 129, 100);
  text-align: center;
}

.change-password,
.delete-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
}
.change-password .exit,
.delete-account .exit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: solid 1px rgb(100, 129, 100);
  border-radius: 25px;
  transform: rotate(45deg);
  font-size: 30px;
  padding-top: 6px;
  padding-left: 6px;
  color: rgb(100, 129, 100);
  background-color: rgb(200, 255, 200);
  margin-bottom: 10px;
  cursor: pointer;
  z-index: 11;
}

.change-password form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 250px;
  height: 250px;
  background-color: rgb(200, 255, 200);
  border: solid 1px rgb(100, 129, 100);
  border-radius: 10px;
  padding: 15px;
}
.change-password form h3 {
  color: rgb(100, 129, 100);
  align-self: center;
}
.change-password form h4 {
  color: rgb(100, 129, 100);
  align-self: flex-start;
}
.change-password form .cp-form-incorrect {
  align-self: center;
  text-align: center;
  color: red;
  -webkit-animation: reveal1 0.1s ease-in forwards;
          animation: reveal1 0.1s ease-in forwards;
}
.change-password form .old-pw,
.change-password form .new-pw {
  width: 100%;
  height: 25px;
  background-color: rgb(200, 255, 200);
  border: solid 2px rgb(100, 129, 100);
  border-radius: 10px;
  padding: 0 5px;
  outline: none;
  color: rgb(100, 129, 100);
}
.change-password form .old-pw::-moz-placeholder, .change-password form .new-pw::-moz-placeholder {
  color: rgb(100, 129, 100);
  opacity: 0.7;
  font-weight: 500;
}
.change-password form .old-pw:-ms-input-placeholder, .change-password form .new-pw:-ms-input-placeholder {
  color: rgb(100, 129, 100);
  opacity: 0.7;
  font-weight: 500;
}
.change-password form .old-pw::placeholder,
.change-password form .new-pw::placeholder {
  color: rgb(100, 129, 100);
  opacity: 0.7;
  font-weight: 500;
}
.change-password form .cp-form-submit {
  color: rgb(100, 129, 100);
  background-color: rgb(200, 255, 200);
  border: solid 2px rgb(100, 129, 100);
  border-radius: 10px;
  cursor: pointer;
}
.change-password form .display {
  opacity: 1;
  z-index: 5;
  transition: 0.3s;
  -webkit-animation: reveal1 0.1s ease-in forwards;
          animation: reveal1 0.1s ease-in forwards;
}

.delete-account form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
  height: 200px;
  background-color: rgb(200, 255, 200);
  border: solid 1px rgb(100, 129, 100);
  border-radius: 10px;
  padding: 15px;
}
.delete-account form h3,
.delete-account form h5 {
  color: rgb(100, 129, 100);
  text-align: center;
  align-self: center;
}
.delete-account form .da-form-incorrect {
  color: red;
  -webkit-animation: reveal1 0.1s ease-in forwards;
          animation: reveal1 0.1s ease-in forwards;
}
.delete-account form .da-form-password {
  width: 100%;
  height: 25px;
  background-color: rgb(200, 255, 200);
  border: solid 2px rgb(100, 129, 100);
  border-radius: 10px;
  padding: 0 5px;
  outline: none;
  color: rgb(100, 129, 100);
}
.delete-account form .da-form-password::-moz-placeholder {
  color: rgb(100, 129, 100);
  opacity: 0.7;
  font-weight: 500;
}
.delete-account form .da-form-password:-ms-input-placeholder {
  color: rgb(100, 129, 100);
  opacity: 0.7;
  font-weight: 500;
}
.delete-account form .da-form-password::placeholder {
  color: rgb(100, 129, 100);
  opacity: 0.7;
  font-weight: 500;
}
.delete-account form .da-form-submit {
  color: rgb(100, 129, 100);
  background-color: rgb(200, 255, 200);
  border: solid 2px rgb(100, 129, 100);
  border-radius: 10px;
  cursor: pointer;
}
.delete-account form .display {
  opacity: 1;
  z-index: 5;
  transition: 0.3s;
  -webkit-animation: reveal1 0.1s ease-in forwards;
          animation: reveal1 0.1s ease-in forwards;
}

.wrong-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.wrong-page h1 {
  text-align: center;
}/*# sourceMappingURL=App.css.map */